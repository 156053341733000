@import '../../assets/scss/abstract/abstract';

.outerContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: var(--yps-text-color);
  text-align: center;

  @include mobile {
    width: 100%;
  }
}

.title {
  font-weight: 300;
  margin: 0;
}

.subtitle {
  margin: 0;
}

.form {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.footer {
  color: var(--yps-input-icon-color);
  padding-top: 100px;
}

.error {
  background-color: var(--yps-danger-color);
  color: var(--yps-bg-color);
  margin-top: 15px;
  display: inline-block;
  padding: 5px 10px;
  font-weight: 500;
  font-size: .9em;
  line-height: 1.6em;

  a {
    color: white;
    text-decoration: underline;
    margin-left: 5px;
  }
}